<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
        <div class="isbel_login_main">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color none_bg">
                    <div class="vx-row">
                        
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center ">
                            <div class="p-8">
                            <div class="login-tabs-container-main">
              <div class="login-tabs-container">
                                <div class="vx-card__title mb-8">
                                  <h4 class="mb-6"><img src="/main-logo.png" alt="login" class="mx-auto"></h4>
                                  <h5 class="mb-5">FORGOT PASSWORD</h5>
                                </div>
                                <div class="form_start_main forget_password_fields">
                                <div class="flex flex-wrap  mb-3">
                                <vs-input
                                  data-vv-validate-on="blur"
                                  name="email"placeholder="email"
                                  icon-no-border
                                  icon="icon icon-at-sign"
                                  icon-pack="feather"
                                  :maxlength=320
                                  v-model="email"
                                  class="w-full"/>
                                </div>
                                <div class="flex flex-wrap my-5 mb-3">
                                <vs-button type="border" class="px-4 w-full" @click="forgotPasswordJWT">Recover Password</vs-button>
                            </div>
                                <div class="flex flex-wrap my-5 mb-3 back_to_login">
                                <vs-button type="border" to="/login" class="px-4 w-full"><i class="feather icon-arrow-left"></i>Back To Login</vs-button>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      email: ''
    }
  },
  methods: {
    forgotPasswordJWT () {
      this.$vs.loading()
       const payload = {
        userDetails: {
          email: this.email,
         
        }
      }
      this.$store.dispatch('auth/forgotPasswordJWT', payload)
        .then(() => { this.$vs.loading.close() })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Alert',
            text: "Please enter valid email address and we'll send you instructions on how to reset your password.",
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    }
  }
}
</script>
